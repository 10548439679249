import React from 'react';
import styles from "./index.module.css";
import Lottie from 'lottie-react';
const Empty = ({message}) => {
  return (
    <div
    className={styles.container}
    >
        <div className={styles.inner}>
              <Lottie
                    size={100}
                    loop
                    animationData={require("../../assets/lottie_animations/empty.json")}
                  />
            <p>{message}</p>
        </div>
    </div>
  )
}

export default Empty;