import { Button, Modal } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { CartContext, UserContext } from "../../../contexts";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { APPURL, DataURLS } from "../../../utils/DataURLS";
import usePost from "../../../hooks/usePost";
import Lottie from "lottie-react";

const MPesaPayment = () => {
  const [cartContext, setCartContext] = useContext(CartContext);
  const [userData, setUserData] = useContext(UserContext);
  const [phone, setPhone] = useState(userData.data?.user?.phone);

  const navigate = useNavigate();

  const params = useParams();

  const add = usePost({ url: `${DataURLS.addBooking}${params.busSlug}` });

  const config = {
    public_key: "FLWPUBK_TEST-ccd0e4fdc25a77b59e2e803a5b76bb24-X",
    tx_ref: Date.now(),
    amount: !isNaN(cartContext.fare * cartContext.seats?.length)
      ? cartContext.fare * cartContext.seats?.length
      : 0,
    currency: "KES",
    payment_options: "card,mobilemoney,ussd",
    country: "KEN",
    redirect_url: `${APPURL}/profile`,
    customer: {
      email: userData.data?.user?.email,
      phone_number: phone,
      name: userData.data?.user?.name,
      user_id: userData.data?.user?._id,
      booking_id:add.results?._id
    },
    customizations: {
      title: "TMS",
      description: `Payment for selected seats (${cartContext.seatsInfo
                    ? cartContext.seatsInfo.map((s) => s.seat).join(",")
                    : ""})`,
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const fwConfig = {
    ...config,
    text: `Submit (Ksh.${cartContext.fare * cartContext.seats?.length})`,
    callback: (response) => {
      console.log(response);
      // add.handleRequest({
      //   body: {
      //     email: userData.data?.user?.email,
      //     phone: phone,
      //     name: userData.data?.user?.name,
      //     address: userData.data?.user?.address,
      //     booking_id:add.results?._id
      //   },
      // });
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {},
  };

  const handlePay = () => {
    let payment = document.querySelector(".flutterwave-pay-btn");
    console.log(payment);
    
    if (payment) {
      
      payment.click();
    }
  };
  //console.log(process.env.PUBLIC_KEY);
  return (
    <div className="pay">
      <div className="details">
        <Modal show={add.loading} size="medium" centered>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width:"50%",
                  height:"50%",
                }}
              >
                <Lottie
                  style={{
                    height:"100%",
                  }}
                  loop
                  animationData={require("../../../assets/lottie_animations/loading2.json")}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <p>Paying for seat number(s)</p>
        {cartContext.seatsInfo?.map((seat, index) => {
          return (
            <div className="div" key={index}>
              <label>Seat: {seat.seat}</label>
              <label>Name: {seat.name ?? "N/A"}</label>
              <label>ID: {seat.id ?? "N/A"}</label>
            </div>
          );
        })}
        <p>
          <b>
            Total : Ksh.
            {!isNaN(cartContext.fare * cartContext.seats?.length)
              ? cartContext.fare * cartContext.seats?.length
              : 0}
          </b>
        </p>
      </div>
      <div className="payment">
        {
          add.error?
          <p style={{
            color:"red"
          }} >
          {add.message}
        </p>:<></>
        }
        <div>
          Enter M-Pesa phone number in the box shown. Click submit and an stk
          push will be sent to your phone.
        </div>

        <div className="user-data">
          <label>Phone number:</label>
          <input
            placeholder="Enter phone number..."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {/* <Button variant='success' >Submit (Ksh.{cartContext.fare*cartContext.seats?.length}) </Button> */}
          <div
            style={{
              width: "100%",
            }}
            onClick={async () => {
              await add.handleRequest({
                body: {
                  email: userData.data?.user?.email,
                  phone: phone,
                  name: userData.data?.user?.name,
                  address: userData.data?.user?.address,
                  passengers: cartContext.seatsInfo
                    ? cartContext.seatsInfo.length
                    : 0,
                  seatNumber: cartContext.seatsInfo
                    ? cartContext.seatsInfo.map((s) => s.seat).join(",")
                    : "",
                  boardingPoints: "",
                  price: !isNaN(cartContext.fare * cartContext.seats?.length)
                    ? cartContext.fare * cartContext.seats?.length
                    : 0,
                },
              });

              if (!add.error) {
                handlePay();
              }

              //closePaymentModal() // this will close the modal programmaticall
            }}
          >
            <Button variant="success" className="btn btn-success pay-btn">
              Book Ksh.
              {!isNaN(cartContext.fare * cartContext.seats?.length)
                ? cartContext.fare * cartContext.seats?.length
                : 0}
            </Button>
          </div>

          <FlutterWaveButton
            className="btn btn-success pay-btn flutterwave-pay-btn"
            {...fwConfig}
          ></FlutterWaveButton>
        </div>
      </div>
    </div>
  );
};

export default MPesaPayment;
