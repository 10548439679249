import React from 'react'
import { useNavigate } from 'react-router-dom';

const StripePayment = () => {
  const navigate = useNavigate();
  const handlePay = ()=>{
    navigate("/profile");
  }
  return (
    <div>Stripe Payment coming soon.</div>
  )
}

export default StripePayment;