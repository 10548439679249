import React, {useContext, useState} from 'react';
import { UserContext } from '../contexts';

const usePost = ({url,options={method:"POST"},reload,setReload=()=>{}}) => {
  const [data, setData] = useState({
    loading: false,
    message: '',
    error: false,
    results: null,
  });

  const [userInfo,setUserInfo] = useContext(UserContext);

  const handleRequest = ({aurl=null,body,aoptions=null}) => {
    if(aurl){
      url = aurl;
    }
    setData({
      error: false,
      message: "",
      loading: true,
      results: data.results,
    });
    options = {...options,headers:{
      "Content-Type":"Application/json",
      "Accept":"Application/json",
      "Authorization":`Bearer ${userInfo?.data?.token}`
    },body:JSON.stringify(body)};

    if(aoptions){
      options = aoptions;
    }
    return new Promise((resolve,reject)=>{
      fetch(url, options)
      .then(req => req.json())
      .then(response => {
        setData({
          error: response.error,
          message: response.error,
          loading: false,
          results: response.data,
        });
        if(reload){
          setReload(!reload);
        }

        resolve(response)
      })
      .catch(error => {
        setData({
          error: true,
          message: 'An error occurred.',
          loading: false,
          results: [],
        });
      });
    })
    
  };

  return {...data,
    handleRequest,
  };
};

export default usePost;
